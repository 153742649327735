import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../barrel/hooks/useHxfTranslation";
import HxfIonPopup from "../../../../CustomElements/ProductionUI/HxfIonPopup/HxfIonPopup";
import SimpleContentPopup from "../../../../CustomElements/ProductionUI/SimpleContentPopup/SimpleContentPopup";
import styles from "./GLSSFS03Popup.module.scss";
import { Backdrop, Checkbox, CircularProgress, InputAdornment, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MathsService from "../../../../../barrel/services/mathsservice";
import DatesConversionService from "../../../../../barrel/services/datesConversionService";
interface IProps{
  infoResult:any;
  onClose:any;
}

const CustomTableHead = styled(
	TableHead
 )((
	 {
		 theme
	 }
 ) => ({
 
    "& th":{
        backgroundColor: '#4b47a5',
        color:'white'
    }
 }));
const CustomTableCell = styled(
	TableCell
 )((
	 {
		 theme
	 }
 ) => ({
  padding:5
 }));
function GLSSFS03Popup(props:IProps){
    const {t} = useHxfTranslation();

    const getSerializedProductObj = () => {
      return props?.infoResult?.serializedProduct;
    }

    const getConsumptionStockMovementsArr = () => {
      return props?.infoResult?.extraData?.consumptionStockMovements ? props?.infoResult?.extraData?.consumptionStockMovements : [];
    }

    const getProperExpirationDateLabel = (objCons:any) => {

      let convertedDate = DatesConversionService().convertUTCStringToLocalDate(objCons?.lotData?.expirationDate);

      return (
        <>{DatesConversionService().formattedDateSimpleLabelDateOnly(convertedDate)}</>
      )
    }

    const getProperQuantity = (objCons:any) => {

      let relevantQty = objCons?._showQuantity !== null && objCons?._showQuantity !== undefined  ? objCons._showQuantity : objCons.Quantity;
      let theQty = MathsService().floatConvert(relevantQty*-1,7);
      if(theQty){
        let res:any = MathsService().floatConvert(theQty*-1,7);
        if(objCons?.uomCode){
          res = res + " " + objCons?.uomCode;
        }
        return res;
      }
      
      return "";
    }
    const getAssociatedConsumptionsElement = () => {

      let consArr = getConsumptionStockMovementsArr();
     return (<>
                <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                              <CustomTableHead>
                                  <TableRow>
                                      <CustomTableCell><b>{t('product')}</b></CustomTableCell>
                          
                                      <CustomTableCell align="center"><b>{t('quantity')}</b></CustomTableCell>
                                      <CustomTableCell align="center">
                                        
                                          <b>{t('location')}</b>
                                       
                                      </CustomTableCell>
                                  </TableRow>
                              </CustomTableHead>
                              <TableBody>
                                
                                  {consArr.map((objCons:any, index:any) => (
                                    <TableRow
                                       
                                      key={"cons_" + index}
                                      className={`${objCons?._markRed ? styles.markRed : ""}`}
                                    
                                    >
                                      <CustomTableCell>
                                        <>
                                        <div>({objCons.code})</div>
                                        <div>{objCons.name}</div>
                                        {objCons?.arrayProductParameterizedVariables && (
                                          <div>
                                            {objCons.arrayProductParameterizedVariables.map((objPVV:any, indexPVV:any) => (
                                              <div key={"cons_" + index+"_PV_" + indexPVV}><b>{objPVV.name}:</b> {objPVV.Value}</div>
                                            ))}
                                          </div>
                                        )}
                                        </>
                                      </CustomTableCell>

                                    
                                        <CustomTableCell align="center">

                                        <div>{getProperQuantity(objCons)}</div>
                                         
                                        </CustomTableCell>
                                        <CustomTableCell align="center">
                                         <div>
                                            <div className={styles.locName}>{t('location')}</div>
                                            <div>{objCons?.warehouseLocationData?.code}</div>
                                            <div className={styles.locName}>{t('warehouse')}</div>
                                            <div>{objCons?.warehouseData?.code}</div>
                                            <div className={styles.locName}>{t('lot')}</div>
                                            <div>{objCons?.lotData?.code}</div>
                                            {objCons?.lotData?.expirationDate && (
                                              <div>
                                                <div className={styles.locName}>{t('expiration.date')}</div>
                                                <div className={styles.expirationLabel}>{getProperExpirationDateLabel(objCons)}</div>
                                              </div>
                                            )}
                                         </div>
                                        </CustomTableCell>
                                    </TableRow>
                                  ))}

                              

                              </TableBody>
                          </Table>
                      </TableContainer>
     </>)
    }
    
    return (
      <HxfIonPopup modelSettings={{customPopupHeight:'90vh'}} title={t('label')} onClose={() => {
        props.onClose();
      }} content={(
        <div className={styles.content}>
         <div>
         {t('serial')}: {getSerializedProductObj()?.serial}
         </div>

         <div>
          <b>{t('production.consumptions')}</b>
         </div>
          {getAssociatedConsumptionsElement()}
        </div>
      )} />
    )

}

export default GLSSFS03Popup;