import { IonButton, IonIcon } from "@ionic/react";
import { TextField } from "@mui/material";
import { addOutline, removeOutline } from "ionicons/icons";
import useHxfTranslation from "../../../../../../../barrel/hooks/useHxfTranslation";
import {
  IProduct,
  IProductFixedVariable, IProductParameterizedVariable
} from "../../../../../../../barrel/modelInterfaces/iProduct";
import { useProductionOrderSelectionStore } from "../../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import {
  buildWasteKey, IMapWastes
} from "../ActionScreenAskProducedProductIrregularity";
import styles from "./SubScreenDefineWastes.module.scss";
import HardcodedBehaviourService from "../../../../../../../barrel/services/hardcodedBehaviourService";
import { useGlobalState } from "../../../../../../../GlobalCustomStateManagement/GlobalStateProvider";
import HxfStockLocationForm from "../../../../../HxfStockLocationForm/HxfStockLocationForm";
import HxfExistingLotsButton, { IExistLotResultLocation } from "../../../../../HxfExistingLotsButton/HxfExistingLotsButton";
import HxfStagedLotsButton from "../../../../../HxfStagedLotsButton/HxfStagedLotsButton";
import { useActionScreenManagerStore } from "../../../ActionScreensManager/ActionScreenManagerStore";
import { useEffect, useState } from "react";

interface IProps {
  fullIrregularityObject:any;
  arrayProductsData: IProduct[]; //Array of products to ask for wastes
  attachBomProducts: boolean;
  mapWastes: IMapWastes; //map with wastes filled
  onModifyWastesQty: any;
  onModifyWasteLocation?:any;
  onModifyWastes?:any;
}
const focusedColor = "#503528";

export const subscreenDefineWastesBuildArrayProductsFromBom = (
  options:any,fullIrregularityObject:any,productBoM: any, sessionState:any
) => {

  let allowedProductFamiliesIds = options?.allowedProductFamiliesIds ? options.allowedProductFamiliesIds : [];
  let onlyDefinedInSameOperation = options?.filterOnlyConsumptionsDefinedInSameOperation;
  let idCurrentOperation = options?.current_Id_OperationProduct;

  let isFilteringProductFamilies = allowedProductFamiliesIds.length > 0;

  let builtArray: IProduct[] = [];

  let ignoreProductsWithOperations = fullIrregularityObject?.resultingWaste_ignoreProductsWithOperations === 1;
  let ignoreProductsWithoutPVS = fullIrregularityObject?.resultingWaste_ignoreProductsWithoutPVS === 1;

  if (productBoM && Object.keys(productBoM).length > 0) {
    let allbomkeys = Object.keys(productBoM);
    for (let i = 0; i < allbomkeys.length; i++) {
      let idProduct = productBoM[allbomkeys[i]].Id_ProductChild;
      let productCode = productBoM[allbomkeys[i]].temp_ProductChild_code;
      let productName = productBoM[allbomkeys[i]].temp_ProductChild_name;
      let idOperationProduct = productBoM[allbomkeys[i]]?.childProduct_Id_OperationProduct;
      let Id_OperationProduct_Base = productBoM[allbomkeys[i]]?.childProduct_Id_OperationProduct_Base;
      let qty = productBoM[allbomkeys[i]]?.Quantity
        ? productBoM[allbomkeys[i]].Quantity
        : 0;
     
      let productFamilies = productBoM[allbomkeys[i]]?.childProductFamilies ? productBoM[allbomkeys[i]]?.childProductFamilies : [];
      
      if(isFilteringProductFamilies){
        let allowProduct = false;
        //check if contains at least one product family
        for(let k = 0; k<productFamilies.length; k++){
          let curProductFamilyId = parseInt(productFamilies[k].Id);
          if(allowedProductFamiliesIds.includes(curProductFamilyId)){
            allowProduct = true;
            break;
          }
        }
        if(!allowProduct){
          continue;
        }
      }
      if(onlyDefinedInSameOperation){
        if(idCurrentOperation !== idOperationProduct){
          continue;
        }
      }
      let ppvvs: IProductParameterizedVariable[] = [];
      let ppfvs: IProductFixedVariable[] = [];

      let productBomPvs: any = productBoM[allbomkeys[i]].parameterizedVariables;
      if (productBomPvs && typeof productBomPvs === "string") {
        productBomPvs = JSON.parse(productBomPvs);
      }
      let productBomPPVs: any =
        productBoM[allbomkeys[i]].productParameterizedVariables;
      if (productBomPPVs && typeof productBomPPVs === "string") {
        productBomPPVs = JSON.parse(productBomPPVs);
      }

      let productBomPCPVS: any =
        productBoM[allbomkeys[i]].productComponent_ParameterizedVariables;
      if (productBomPCPVS && typeof productBomPCPVS === "string") {
        productBomPCPVS = JSON.parse(productBomPCPVS);
      }

      let productHasPvs = productBomPPVs && productBomPPVs.length > 0;
      if (productBomPPVs) {
        for (let j = 0; j < productBomPPVs.length; j++) {
          let idParameterizedVariable =
            productBomPPVs[j]["Id_ParameterizedVariable"];
          let idProductParameterizedVariable = productBomPPVs[j]["Id"];

          let pvName = "";
          let pvCode = "";

          for (let k = 0; k < productBomPvs.length; k++) {
            if (productBomPvs[k]["Id"] === idParameterizedVariable) {
              pvName = productBomPvs[k]["name"];
              pvCode = productBomPvs[k]["code"];
              break;
            }
          }

          let pvValue = "";
          if(productBomPCPVS){
            for (let k = 0; k < productBomPCPVS.length; k++) {
              if (
                productBomPCPVS[k]["Id_Product_ParameterizedVariable"] ===
                idProductParameterizedVariable
              ) {
                pvValue = productBomPCPVS[k]["Value"];
                break;
              }
            }
  
          }

          let productComponentParameterizedVariable: IProductParameterizedVariable =
            {
              Id_Product_ParameterizedVariable: idProductParameterizedVariable,
              name: pvName,
              code: pvName,
              Value: pvValue,
            };

          ppvvs.push(productComponentParameterizedVariable);
        }
      }


      let checkShouldIgnoreThisProductWithOperations =  ignoreProductsWithOperations && Id_OperationProduct_Base !== 0 && Id_OperationProduct_Base !== undefined;

      let checkShouldIgnoreThisProductWithoutPVS = ignoreProductsWithoutPVS && !productHasPvs;
  
      let shouldIgnoreProduct = checkShouldIgnoreThisProductWithOperations || checkShouldIgnoreThisProductWithoutPVS;
      if(shouldIgnoreProduct){
        continue;
      }

      let hardcodedCheckShouldHideVisually = HardcodedBehaviourService().checkIfHideButStillWaste_actionScreenAskProducedProductBuildWastesProduct(fullIrregularityObject,productBoM[allbomkeys[i]],sessionState);
  
      let product: IProduct = {
        Id_Product: idProduct,
        code: productCode,
        name: productName,
        productParameterizedVariablesData: ppvvs,
        productFixedVariablesData: ppfvs,
        Quantity: qty,
        productFamilies:productFamilies
      };
      if(hardcodedCheckShouldHideVisually){
        product._visuallyHideFromWastes = true;
      }
      builtArray.push(product);
    }
  }

  return JSON.parse(JSON.stringify(builtArray));
};

function ActionScreenIrregularityDefineWastes(props: IProps) {
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const { sessionState } = useGlobalState();
  const currentActionScreenManager = useActionScreenManagerStore();
  const [didMount, setDidMount] = useState(false);
  const {t} = useHxfTranslation();
  const getWasteQtyValue = (product: IProduct) => {
    let key = buildWasteKey(product);

    if (props.mapWastes[key]) {
      return props.mapWastes[key].Quantity;
    }
    return 0;
  };

  const getStagedMaterialData = () => {

//currentActionScreenManager?.currentActionConfig?._stagedArrayMaterialComponentsData
    return productionOrderSelectionStore?.stagedMaterial ? productionOrderSelectionStore?.stagedMaterial : [];
  }
  const getWasteObj = (product: IProduct) => {
    let key = buildWasteKey(product);

    if (props.mapWastes[key]) {
      return props.mapWastes[key];
    }
    return null;
  }
  const buildArrayProductsData = () => {
    let opts:any = {};
    let idCurrentOperation = productionOrderSelectionStore?.currentOperation?.Id;
    opts.current_Id_OperationProduct = idCurrentOperation;
    if(props?.fullIrregularityObject?.resWasteBom_filter_Id_ProductFamily){
      opts.allowedProductFamiliesIds = [parseInt(props.fullIrregularityObject.resWasteBom_filter_Id_ProductFamily)];
    }
    
    if(props?.fullIrregularityObject?.resWasteBom_filterSameOperationCons){
        opts.filterOnlyConsumptionsDefinedInSameOperation = true;
    }
    let builtArray: any = subscreenDefineWastesBuildArrayProductsFromBom(
      opts,
      props.fullIrregularityObject,
      productionOrderSelectionStore?.product?.product_BoM,
      sessionState
    ); //productionOrderSelectionStore?.product?.product_BoM;
    
    if (!props.attachBomProducts) {
      builtArray = [];
    }

    return builtArray;
  };

  const arrayProductsData = buildArrayProductsData();


  useEffect(() => {
    if(!didMount){
      if(props?.fullIrregularityObject?.resWasteBom_askWasteLoc){
          let wastesClean = [];
          let cleanObjArrayProductsData = JSON.parse(JSON.stringify(arrayProductsData));
          for(let i = 0; i<cleanObjArrayProductsData.length; i++){
            let productWaste = getWasteObj(cleanObjArrayProductsData[i]);
      
            if(!productWaste){
              continue;
            }
            let actionDefault_Id_WarehouseLocation = props.fullIrregularityObject?.actionDefault_Id_WarehouseLocation;
            let actionDefault_Id_Warehouse = props.fullIrregularityObject?.actionDefault_Id_Warehouse;
            let actionDefault_Id_Lot = props.fullIrregularityObject?.actionDefault_Id_Lot;
            let actionDefault_warehouseLocationName = props.fullIrregularityObject?.actionDefault_warehouseLocationName;
            let actionDefault_warehouseName = props.fullIrregularityObject?.actionDefault_warehouseName;
            let actionDefault_lotName = props.fullIrregularityObject?.actionDefault_lotName;
            let newLocationData:any = {};
            if(actionDefault_Id_WarehouseLocation){
              newLocationData.Id_WarehouseLocation = actionDefault_Id_WarehouseLocation;
              newLocationData.warehouseLocationName = actionDefault_warehouseLocationName;
            }
            if(actionDefault_Id_Warehouse){
              newLocationData.Id_Warehouse = actionDefault_Id_Warehouse;
              newLocationData.warehouseName = actionDefault_warehouseName;
            }
            if(actionDefault_Id_Lot){
              newLocationData.Id_Lot= actionDefault_Id_Lot;
              newLocationData.lotName = actionDefault_lotName;
            }
            productWaste.locationData = newLocationData;

            wastesClean.push(productWaste);
            
          }

          props.onModifyWastes(wastesClean);
      }
      setDidMount(true);
    }
  },[didMount]);


  if(arrayProductsData && arrayProductsData.length === 0){
    return (
      <div>
        <b>{t('nothing.to.register')}</b>
      </div>
    )
  }
  return (
    <>
      <div className={styles.tableArea}>
        <div className={styles.consumptionsTableContainer}>
          <div className={styles.consumptionsTableBody}>
            <div className={styles.consumptionsRow}>
              <div
                className={`${styles.consumptionsBodyCellHeader} ${styles.consumptionsLeftHeader}`}
              >
                {t('product')}
              </div>
              <div className={`${styles.consumptionsBodyCellHeader}`}>
              {t('wasted.qty')}
              </div>
              {props?.fullIrregularityObject?.resWasteBom_askWasteLoc === 1 ? (
                <div className={`${styles.consumptionsBodyCellHeader}`}>
                  {t('location')}
                </div>
              ) : (<></>)}
            </div>

            {arrayProductsData.map((obj: IProduct, index: number) => (
              <div key={"bom_p_" + index} className={styles.consumptionsRow}>
                {!obj?._visuallyHideFromWastes && (
                 <>
                <div
                  className={`${styles.consumptionsBodyCell} ${styles.consumptionsProductCol}`}
                >
                  <div className={styles.consumptionProductName}>
                    <div> ({obj.code}) </div>
                    <div> {obj.name} </div>
                  </div>

                  {obj.productParameterizedVariablesData.length > 0 && (
                    <div>
                      <div>
                        <div
                          className={
                            styles.consumptionsParameterizedVariablesLabel
                          }
                        >
                          <b>{t('parameterizedvariables')}:</b>
                        </div>
                        {obj.productParameterizedVariablesData.map(
                          (
                            objPPv: IProductParameterizedVariable,
                            indexPv: number
                          ) => (
                            <div
                              className={
                                styles.consumptionsParameterizedVariablesAndValues
                              }
                              key={"bom_p_pv_" + indexPv + "_pindex_" + index}
                            >
                              {objPPv.name}:{objPPv.Value}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.consumptionsBodyCell}>
                  <div className={styles.consumptionsQtyContainer}>
                    <div className={styles.consumptionsQty}>
                      <TextField
                        inputProps={{
                          min: 0,
                          style: { width: "85px", textAlign: "center" },
                        }}
                        disabled={false}
                        error={false}
                        onChange={(evt) => {
                          let newQty = evt.target.value;
                          props.onModifyWastesQty(obj, newQty);
                        }}
                        value={getWasteQtyValue(obj)}
                        label={"Qty"}
                        variant="outlined"
                      />
                    </div>

                  </div>
                  <div style={{display:'flex',justifyContent:'center'}}>
                        <IonButton
                          data-btn={"irr-btn-subtract-idx-" + index}
                          disabled={false}
                          className={styles.addrembtn}
                          onClick={() => {
                             
                              let qt = getWasteQtyValue(obj);
                              if(!qt){
                                  qt = 0;
                              }

                              let newQt = parseInt(qt) - 1;
                              if(newQt < 0){
                                  newQt = 0;
                              }
                    
                    
                              props.onModifyWastesQty(obj, newQt);
                          }}
                      >
                          <IonIcon slot="icon-only" icon={removeOutline}></IonIcon>
                        </IonButton>

                        <IonButton
                          data-btn={"irr-btn-subtract-idx-" + index}
                          disabled={false}
                          className={styles.addrembtn}
                          onClick={() => {
                            let qt = getWasteQtyValue(obj);
                            if(!qt){
                                qt = 0;
                            }

                            let newQt = parseInt(qt) +1;
                            props.onModifyWastesQty(obj, newQt);
                          }}
                      >
                          <IonIcon slot="icon-only" icon={addOutline}></IonIcon>
                        </IonButton>
                    </div>
                </div>
                {props?.fullIrregularityObject?.resWasteBom_askWasteLoc === 1 ? (
                  <div className={styles.consumptionsBodyCell}>
          
                    
                      <HxfStockLocationForm
                        textModeWhenFilled={true}
                        descriptionsMode="SHORT"
                        customWarehouseLocationLabel={t('location')}
                        minWidthInputs={140}
                        disableNoLotUseDefault={false}
                        callbackAddWarehouseLocationCacheItems={
                          () => {

                          }
                        }
                        callbackAddWarehouseCacheItems={
                          () => {

                          }
                        }
                        /*callbackAddLotCacheItems={callbackAddLotCacheItems}*/
                        allowLotCreation={false}
                        warehouseSearchFilters={getWasteObj(obj)?.locationData?.Id_WarehouseLocation ? {Id_WarehouseLocation:getWasteObj(obj)?.locationData?.Id_WarehouseLocation} : null}
                        lotSearchFilters={getWasteObj(obj)?.locationData?.Id_Warehouse ? {Id_Warehouse:getWasteObj(obj)?.locationData?.Id_Warehouse} : null}
                        warehouseLocationValue={getWasteObj(obj)?.locationData?.Id_WarehouseLocation ? {label:getWasteObj(obj)?.locationData?.warehouseLocationName, value: getWasteObj(obj)?.locationData?.Id_WarehouseLocation} : null}
                        warehouseValue={getWasteObj(obj)?.locationData?.Id_Warehouse ? {label:getWasteObj(obj)?.locationData?.warehouseName, value: getWasteObj(obj)?.locationData?.Id_Warehouse} : null}
                        defaultNoLot={null}
                        lotValue={getWasteObj(obj)?.locationData?.Id_Lot ? {label:getWasteObj(obj)?.locationData?.lotName, value: getWasteObj(obj)?.locationData?.Id_Lot} : null}
                        warehouseError={false}
                        lotError={false}
                        warehouseLocationError={false}
                        onWarehouseLocationChange={(val: any) => {
                        
                          let objId = val?.fetchedData?.Id;
                          let objCode = val?.fetchedData?.code;
                          let objName = val?.fetchedData?.name;
                          let newObj = {};
                          if(!objId){
                            //clear
                            props.onModifyWasteLocation(obj, null);
                          }else{
                            props.onModifyWasteLocation(obj, {Id_WarehouseLocation:objId, warehouseLocationName:objName});
                          }

                        }}
                        onWarehouseChange={(val: any) => {
                          let objId = val?.fetchedData?.Id;
                          let objCode = val?.fetchedData?.code;
                          let objName = val?.fetchedData?.name;
                          let newObj = {};

                          let currentLocationData = getWasteObj(obj)?.locationData ? getWasteObj(obj)?.locationData : {};
                          if(!objId){
                            //clear
                            props.onModifyWasteLocation(obj, {...currentLocationData, Id_Warehouse:null, warehouseName:null, Id_Lot:null, lotName:null});
                          }else{
                            props.onModifyWasteLocation(obj, {...currentLocationData, Id_Warehouse:objId, warehouseName:objName});
                          }
                        }}
                        onLotChange={(val: any) => {
                          
                          let objId = val?.fetchedData?.Id;
                          let objCode = val?.fetchedData?.code;
                          let objName = val?.fetchedData?.name;
                          let newObj = {};

                          let currentLocationData = getWasteObj(obj)?.locationData ? getWasteObj(obj)?.locationData : {};
                          if(!objId){
                            //clear
                            props.onModifyWasteLocation(obj, {...currentLocationData, Id_Lot:null, lotName:null});
                          }else{
                            props.onModifyWasteLocation(obj, {...currentLocationData, Id_Lot:objId, lotName:objName});
                          }
                        }}
                      />

                      <div className={styles.lotToolsContainer}>
                        <HxfExistingLotsButton 
                        productName={getWasteObj(obj)?.name} 
                        Id_Product={getWasteObj(obj)?.Id_Product}
                        arrayProductParameterizedVariables={getWasteObj(obj)?.productParameterizedVariablesData}
                        onLotSelection={(data:IExistLotResultLocation) => {
                         
                          let useIdWarehouse = null;
                          let useWarehouseName = null;
                          let useIdWarehouseLocation = null;
                          let useWarehouseLocationName = null;
                          let useIdLot = null;
                          let useLotCode = null;
                          if(data?.Id_WarehouseLocation){
                            useIdWarehouseLocation = data?.Id_WarehouseLocation;
                            useWarehouseLocationName = data?.warehouseLocationName;
                          }
                          if(data?.Id_Warehouse){
                            useIdWarehouse = data?.Id_Warehouse;
                            useWarehouseName = data?.warehouseName
                          }

                          if(data?.Id_Lot){
                            useIdLot = data?.Id_Lot;
                            useLotCode = data?.lotCode;
                          }
                          let currentLocationData = getWasteObj(obj)?.locationData ? getWasteObj(obj)?.locationData : {};
                          props.onModifyWasteLocation(obj, {
                            ...currentLocationData,
                            Id_WarehouseLocation:useIdWarehouseLocation,
                            warehouseLocationName:useWarehouseLocationName,
                            Id_Warehouse:useIdWarehouse,
                            warehouseName:useWarehouseName,
                            Id_Lot:useIdLot,
                            lotName:useLotCode
                          });
                        }}
                        />

                        
                        {props.fullIrregularityObject?.resWasteBom_stagedLotsShortcut === 1 && (
                        <HxfStagedLotsButton 
                            bomChild={{Id_ProductChild:getWasteObj(obj)?.Id_Product,childProduct_name:getWasteObj(obj)?.name,arrayProductParameterizedVariables: getWasteObj(obj)?.productParameterizedVariablesData ?  getWasteObj(obj)?.productParameterizedVariablesData : []}}
                            stagedArrayMaterialComponentsData={getStagedMaterialData()}
                            onLotSelection={(data:IExistLotResultLocation)=> {
                              let useIdWarehouse = null;
                              let useWarehouseName = null;
                              let useIdWarehouseLocation = null;
                              let useWarehouseLocationName = null;
                              let useIdLot = null;
                              let useLotCode = null;
                              if(data?.Id_WarehouseLocation){
                                useIdWarehouseLocation = data?.Id_WarehouseLocation;
                                useWarehouseLocationName = data?.warehouseLocationName;
                              }
                              if(data?.Id_Warehouse){
                                useIdWarehouse = data?.Id_Warehouse;
                                useWarehouseName = data?.warehouseName
                              }
    
                              if(data?.Id_Lot){
                                useIdLot = data?.Id_Lot;
                                useLotCode = data?.lotCode;
                              }
                              let currentLocationData = getWasteObj(obj)?.locationData ? getWasteObj(obj)?.locationData : {};
                              props.onModifyWasteLocation(obj, {
                                ...currentLocationData,
                                Id_WarehouseLocation:useIdWarehouseLocation,
                                warehouseLocationName:useWarehouseLocationName,
                                Id_Warehouse:useIdWarehouse,
                                warehouseName:useWarehouseName,
                                Id_Lot:useIdLot,
                                lotName:useLotCode
                              });
                            }}
                         />
                        )}

                      </div>
                  </div>
                ) : (<></>)}
                </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionScreenIrregularityDefineWastes;
