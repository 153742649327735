import { useCallback, useEffect, useState } from "react";
import serializedProductsController from "../../../../../../barrel/controllers/serializedProductsController";
import useFeedbackService from "../../../../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../../../../barrel/hooks/useHxfTranslation";
import useProductivityScale from "../../../../../../barrel/hooks/useProductivityScale";
import useProductSerialization from "../../../../../../barrel/hooks/useProductSerialization";
import { useProductionOrderSelectionStore } from "../../../../../FeaturePages/ProductionOrders/Standard/store/productionOrderSelectionStore";
import { useActionScreenManagerStore } from "../../ActionScreensManager/ActionScreenManagerStore";
import { IPopupPrintSPSerializedProduct } from "../ActionScreenPrintSerializationLabels/ActionScreenPrintSerializationLabels";
import { dispatchActionScreenPrintSerializationLabelsStore } from "../ActionScreenPrintSerializationLabels/ActionScreenPrintSerializationLabelsStore";
import ActionScreenTopBar from "../Components/ActionScreenTopBar";
import styles from "./ActionRegisterProductSerialization.module.scss";
import { dispatchActionScreenRegisterProductSerializationStateStore, useActionScreenRegisterProductSerializationStateStore } from "./subviews/ActionScreenRegisterProductSerializationStore";
import ActionRegisterProductSerializationAutoView from "./subviews/Auto/ActionRegisterProductSerializationAutoView";
import ActionRegisterProductSerializationManualView from "./subviews/Manual/ActionRegisterProductSerializationManualView";
import ActionRegisterProductSerializationSkip from "./subviews/Skipper/ActionRegisterProductSerializationSkip";

interface IResultAutoGenerate {
  method:"AUTO_GENERATE";
  quantity:any;
  skipSerialsRegistration?:boolean;
}

interface IProps{
    callbackOnClose:any;
    callbackSuccessConfirm:any;
}

function ActionRegisterProductSerialization(props: IProps) {
  const [indexkey, setIndexkey] = useState(0);
  const [showErrorInput, setShowErrorInput] = useState(false);

  const feedbackService = useFeedbackService();
  const currentActionScreenManager = useActionScreenManagerStore();
  const {t} = useHxfTranslation();
  const productionOrderSelectionStore = useProductionOrderSelectionStore();
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false);

  const actionScreen = useActionScreenRegisterProductSerializationStateStore();

  const productSerialization = useProductSerialization();

  // states to allow clearing when loading a default stock location config

  const closeCallback = () => {
    props.callbackOnClose(
      currentActionScreenManager?.currentActionConfig?.method
    );
  };
  function stepConfirm(res: any) {
    /*let storeResult = confirmQuantity(res);
            if(storeResult !== false){
                    //
                    if(storeResult.quantity > 0){
                        let addingIrregularityObj = {...addingIrregularityData, filledIrregularityData:{...addingIrregularityData.filledIrregularityData, quantity: storeResult.quantity}};
                    
                        
                        
                    }
                    
                    setCurrentStep("SELECT_IRREGULARITY");
            } */
  }

  useEffect(() => {
    if (!didMount) {
      //mounting

      setIsLoading(false);
      setDidMount(true);
    }
  }, [
    didMount,
    currentActionScreenManager?.currentActionConfig?.irregularitysList,
    currentActionScreenManager?.currentActionConfig?.machinesList,
  ]);

  if (isLoading) {
    return <></>;
  }


  if(currentActionScreenManager?.currentActionConfig?.method === "AUTO_GENERATE"){
    //todo if already has serializedproducts, show msg and skip

    let willRequireRegenerationOfSerials = false;
    //we only want to require regeneration of serials if the current reserved serials are !== from the quantity of produced product
    let totalSerialsWouldRegister = productSerialization.getTotalSerialsWouldRegister();

    let totalAlreadyReservedSerials = 0;

    if(actionScreen?.totalReservedSerials !== null){
      totalAlreadyReservedSerials = actionScreen.totalReservedSerials;
    }else{
      totalAlreadyReservedSerials = currentActionScreenManager?.currentActionConfig?._totalPrAlreadyRegisteredSps;
    }
    
    if(!totalAlreadyReservedSerials){
      totalAlreadyReservedSerials = 0;
    }

    willRequireRegenerationOfSerials = totalAlreadyReservedSerials !== totalSerialsWouldRegister;
    if(!willRequireRegenerationOfSerials){
      return (
        <ActionRegisterProductSerializationSkip callbackOnClose={closeCallback} callbackSuccessConfirm={() => {
          props.callbackSuccessConfirm();
        }}/>
      )
    }

    
    return(
        <ActionRegisterProductSerializationAutoView callbackSuccessConfirm={(result:IResultAutoGenerate) => {

          let method = result?.method;
          let amountSerials = result?.quantity;
          
          if(method === "AUTO_GENERATE"){
            if(amountSerials > 0 && currentActionScreenManager?.currentActionConfig?.directReserve === 1){

              let idProductionRecord = productionOrderSelectionStore?.productionRecord?.Id;

              //let arrayPPVS = productionOrderSelectionStore?.productionOrderProduct_ParameterizedVariableValues;
              

              serializedProductsController().reserveSerial({
                amount:amountSerials,
                Id_ProductionRecord:idProductionRecord,
                cancelPrevSerializedProducts:true
              }).then((res:any) => {
          
                if(!res?.data?.reserved){
                  throw "unexpected reservation error";
                }
              
                let totalReservedSerials = res?.data?.arrayResult ? res?.data?.arrayResult?.length : 0;
                

                let arrResult = res?.data?.arrayResult;
                let newPrintSerializableProducts:IPopupPrintSPSerializedProduct[] = [];
                if(arrResult){
                  for(let i = 0; i<arrResult.length; i++){
                    newPrintSerializableProducts.push({
                      Id:arrResult[i].Id_SerializedProduct,
                      serial:arrResult[i].serial
                    })
                  }
                }
                
                dispatchActionScreenRegisterProductSerializationStateStore({
                  type: "SET_TOTAL_RESERVED_SERIALS",
                  payload: totalReservedSerials,
                });

                //update printable serialized products, to use the reserved sps instead of the loaded ones (overwritten)
                dispatchActionScreenPrintSerializationLabelsStore({
                  type:"SET_SERIALIZED_PRODUCTS",
                  payload:newPrintSerializableProducts
                });

                result.skipSerialsRegistration = true;
                props.callbackSuccessConfirm(result);
              }).catch((res:any) => {
           
                feedbackService.notifyToast(t('generic.critical.error'),"error");
                return;
              })
            }
          }else{
            props.callbackSuccessConfirm(result);
          }

          
          

        }} callbackOnClose={closeCallback}/>
    )
  }
  if(currentActionScreenManager?.currentActionConfig?.method === "MANUAL_ASSIGNMENT"){
    return(
        <ActionRegisterProductSerializationManualView callbackSuccessConfirm={(result:IResultAutoGenerate) => {
            props.callbackSuccessConfirm(result);

        }} callbackOnClose={closeCallback}/>
    )
  }
  
  return (
    <>
      <div key={indexkey + "_forceRerender"} className={styles.container}>
        <ActionScreenTopBar
          callbackOnClose={closeCallback}
          title="Register Product Serialization"
        />

        <div className={styles.content}>
          <div className={styles.popupInfoQuestion}>
            Unexpected view
          </div>

          <div className={`${styles.selectionOuter}`}></div>

        </div>
      </div>

      {/*<ActionScreenAskPrintLabelsConfirmationButtons
        currentActionConfig={currentActionScreenManager?.currentActionConfig}
        finishedActionCallback={() => {
          console.log("Should finish this action now");
          props.callbackSuccessConfirm();
        }}
      />*/}

    </>
  );
}
export default ActionRegisterProductSerialization;
