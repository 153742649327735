function HxfNumberFormat(){
    const val = (number:any):any => {
        if((parseFloat(number) < 0.00000000001 && parseFloat(number) > 0) || (parseFloat(number) > -0.00000000001 && parseFloat(number) < 0)){
            return 0;
        }
        let formattedNumber = parseFloat(number).toFixed(11); // Formats the number to have 3 decimal places
        formattedNumber = formattedNumber.replace(/\.?0*$/, ''); // Removes trailing zeros after the decimal point
        return formattedNumber;

    };
    return {val}
}

export default HxfNumberFormat;