import { useState } from "react";
import stockManagementController from "../../../barrel/controllers/stockManagementController";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import ExistingLotsPopup from "../ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskBomConsumptions/Subpopups/ExistingLotsPopup/ExistingLotsPopup";
import styles from "./HxfExistingLotsButton.module.scss";
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

export interface IExistLotResultLocation{
    Id_WarehouseLocation?:any,
    warehouseLocationCode?:any,
    warehouseLocationName?:any,
    warehouseCode?:any,
    warehouseName?:any,
    Id_Warehouse?:any,
    Id_Lot?:any,
    lotCode?:any
}

interface IProps{
    productName?:any;
    Id_Product?:any;
    arrayProductParameterizedVariables?:any;

    onLotSelection?:(location: IExistLotResultLocation) => void;
}

const HxfExistingLotsButton = (props:IProps) => {

    const feedbackService = useFeedbackService();
    const {t} = useHxfTranslation();
    const [askExistingLotSelection,setAskExistingLotSelection] = useState<any>(null);

   

    return (
        <div>
             {askExistingLotSelection && (
        <ExistingLotsPopup
        topBar={{label:askExistingLotSelection?.productName}}
        selectableLots={askExistingLotSelection?.locationsQuantity}
         onClose={() => {
          setAskExistingLotSelection(null);
         }}
         onConfirm={(result:any) => {
          let selectedLot = result?.selectedLot;
          if(!selectedLot){
            return;
          }
          let updatingLocationData:any = {};
         
            updatingLocationData.newLotData = { //for lots we only need the lot code directly as it is calculated in the backend
              lotValue:selectedLot.lotData.code
            };
          
         
            updatingLocationData.newWhData = { 
              Id:selectedLot.Id_Warehouse,
              code:selectedLot.warehouseData.code,
              name:selectedLot.warehouseData.name
            };
          
      
            updatingLocationData.newWhLocData = { 
              Id:selectedLot.Id_WarehouseLocation,
              code:selectedLot.warehouseLocationData.code,
              name:selectedLot.warehouseLocationData.name
            };
          
     
            if(props?.onLotSelection){
                props.onLotSelection({
                    Id_WarehouseLocation:selectedLot.Id_WarehouseLocation,
                    warehouseLocationCode: selectedLot?.warehouseLocationData?.code,
                    warehouseLocationName: selectedLot?.warehouseLocationData?.name,
                    warehouseCode: selectedLot?.warehouseData?.code,
                    warehouseName: selectedLot?.warehouseData?.name,
                    Id_Warehouse: selectedLot?.Id_Warehouse,
                    Id_Lot: selectedLot?.Id_Lot,
                    lotCode: selectedLot?.lotData?.code
                });
            }

            setAskExistingLotSelection(null);
         }}
        />
      )}
        <div className={styles.lotToolBtnExistingLots} onClick={() => {
           
            stockManagementController().checkProductsLotsExistance({
              lotPrecisionPage:1,
              productFilter:{
                Id_Product:props?.Id_Product,
                stockMovement_productParameterizedVariables:props?.arrayProductParameterizedVariables ? props.arrayProductParameterizedVariables : [],
                exactProductParameterizedVariables:true,
                exactProductStockLocation:false
              }
            }).then((res:any) => {
             
                
                if(res?.data?.response?.data === undefined){
                  throw "unexpected res";
                }
                let existingLotsStockData =res?.data?.response?.data?.[0]?.stockResult ? res?.data?.response?.data?.[0]?.stockResult : [];
             
                if(existingLotsStockData.length === 0){
                  feedbackService.notifyToast(t('no.existing.lots.available.product'), "error");
                  return;
                }
                let cleanLocationQtyForThisProduct = [];
                for(let i = 0; i<existingLotsStockData.length; i++){
                  if(!existingLotsStockData[i]?.Id_Lot){
                    continue; //skip shopfloor
                  }
                  cleanLocationQtyForThisProduct.push({
                    Id_WarehouseLocation:existingLotsStockData[i].Id_WarehouseLocation,
                    Id_Warehouse:existingLotsStockData[i].Id_Warehouse,
                    Id_Lot:existingLotsStockData[i].Id_Lot,
                    warehouseLocationData:{
                      name:existingLotsStockData[i].warehouseLocationName,
                      code:existingLotsStockData[i].warehouseLocationCode
                    },
                    warehouseData:{
                      name:existingLotsStockData[i].warehouseName,
                      code:existingLotsStockData[i].warehouseCode
                    },
                    lotData:{
                      name:existingLotsStockData[i].lotName,
                      code:existingLotsStockData[i].lotCode,
                      expirationDate: existingLotsStockData[i].lotExpirationDate,
                     
                    },
                    availableQuantity:existingLotsStockData[i].Quantity
                  });
                }

                if(cleanLocationQtyForThisProduct.length === 0){
                  feedbackService.notifyToast(t('no.existing.lots.available.product'), "error");
                  return;
                }

                setAskExistingLotSelection({
                  productName:props.productName,
                  locationsQuantity:cleanLocationQtyForThisProduct
                });

            }).catch((res:any) => {
              feedbackService.notifyToast(t('error.unexpected'), "error");
            });
          
          }}>
            <div><SavedSearchIcon/></div>
            <div>{t('existing.lots')}</div>
          </div>
        </div>
    )
}

export default HxfExistingLotsButton;