import styles from "./HxfIonPopup.module.scss";
import {
    IonButton,
    IonModal,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonPage,
    IonItem,
    IonInput,
    IonIcon
  } from '@ionic/react';
import useHxfTranslation from "../../../../barrel/hooks/useHxfTranslation";
import { closeOutline } from "ionicons/icons";
import styled from "@emotion/styled";
import { CSSProperties } from "styled-components";
interface IModalSettings{
    mode?:null | "fullscreen";
    disableOverflowScroll?:boolean;
    contentHeightPct?:any;

    customPopupHeight?:any;
    customPopupMaxHeight?:any;
    customPopupMinHeight?:any;

}
interface IProps{
    onDismiss?:any;
    style?:any;
    dataPopupContainerValue?:any;
    className?:any;
    content?:any;
    onClose?:any;
    bottomConfirmationContainer?:any;
    modelSettings?:IModalSettings;
    title?:any;
}
const noFullscreen = `
.hxf-modal{
--max-width:90%;
--max-height:90%;
--backdrop-opacity: 0.53;
}

`;


function HxfIonPopup(props: IProps) {

  const getCustomHeightStyle = () => {
    let strStyle = "";
    if(props?.modelSettings?.customPopupHeight){
      strStyle += "--height:" + props?.modelSettings?.customPopupHeight + ";";
    }

      if(props?.modelSettings?.customPopupMaxHeight){
      strStyle += "--max-height:" + props?.modelSettings?.customPopupMaxHeight + ";";
    }
    if(props?.modelSettings?.customPopupMinHeight){
      strStyle += "--min-height:" + props?.modelSettings?.customPopupMinHeight + ";";
    }
    return strStyle;
  }
  const customModalHeight = `.hxf-custom-modal{
    `
    + getCustomHeightStyle() +
    `
  }`;
  const {t} = useHxfTranslation();
  const getCustomStyle = () =>{
    
    if(!props?.style){
      return {};
    }
    return {...props.style};
  }

  const getContentHeight = () => {
    if(props?.modelSettings?.contentHeightPct){
      return props.modelSettings.contentHeightPct;
    }
    if(!props.bottomConfirmationContainer){
        return 100;
    }

    return 75;
  }


  const getBottomContainerStyles = ():CSSProperties => {
    let calculateBOTTOMPCT = false;

    if(!calculateBOTTOMPCT){
      return {};
    }
    return {height:getBottomHeight() + "%"}; //deprecated ( ?) it was messing up the height auto scaling for the bottom.
  }
  const getBottomHeight = () => {
    let remaining = 100 - getContentHeight();
    return remaining;
  }

  return (
    <div className={"noFullScreenIonModal"}>
     <style>{noFullscreen}</style>
     <style>{customModalHeight}</style>
     <IonModal  class={`hxf-custom-modal hxf-modal ${props.modelSettings?.mode === "fullscreen" ? "modal-fullscreen" : ""}` } trigger="open-modal" isOpen={true} backdropDismiss={false} onDidDismiss={() => {
        if(props?.onDismiss){
            props.onDismiss();
        }
     }}>
        <div className={`${styles.barContainer}`}>
            <div></div>

            <div className={styles.title}>{props?.title}</div>

            <IonButton
                color="danger"
                className={styles.closeWindowButton}
                onClick={() => {
                if (props?.onClose) {
                    props.onClose();
                }
                }}
            >
                <IonIcon slot="icon-only" size="large" icon={closeOutline} />
            </IonButton>
        </div>
        <div
            data-popup-container={props?.dataPopupContainerValue ? props.dataPopupContainerValue : null}
            style={{height:getContentHeight() + "%"}}
            className={` ${styles.contentContainer} ${props.modelSettings?.disableOverflowScroll ? "": styles.overflowScroll} `}
        >
            {props.content}
        </div>
        {props.bottomConfirmationContainer && (
            <>
                <div className={styles.dividerContainer}>
                    <div className={styles.divider}></div>
                </div>
            
                <div className={styles.bottomContainer} style={getBottomContainerStyles()} >
                    {props.bottomConfirmationContainer}
                </div>
            </>

        )}
      </IonModal>
    </div>
  );
}

export default HxfIonPopup;
