import styles from "./HxfStagedLotsButton.module.scss";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import useHxfTranslation from "../../../barrel/hooks/useHxfTranslation";
import useFeedbackService from "../../../barrel/hooks/useFeedbackService";
import { useState } from "react";
import StagedLotsPopup from "../ProductionUI/ProductionOperationConfirmation/ActionScreens/ActionScreenAskBomConsumptions/Subpopups/StagedLotsPopup/StagedLotsPopup";
import { IExistLotResultLocation } from "../HxfExistingLotsButton/HxfExistingLotsButton";


interface IStagedMaterial{
    Id_Product?:any;
    arrayProductParameterizedVariables?:any;
    locationsQuantity?:any;
}
interface IBomChild{
    Id_ProductChild?:any;
    arrayProductParameterizedVariables?:any;
    childProduct_name?:any;
}
interface IProps{
    stagedArrayMaterialComponentsData:IStagedMaterial[];
    bomChild:IBomChild;

    onLotSelection?:(location: IExistLotResultLocation) => void;
    
}
const HxfStagedLotsButton = (props:IProps) => {

    const {t} = useHxfTranslation();
    const feedbackService = useFeedbackService();
    const [askStagedLotSelection,setAskStagedLotSelection] = useState<any>(null);
    const buildKeyForChildProduct = (productComponent:any) => {
    
        let idProduct = productComponent.Id_ProductChild;
        let arrPpvs = productComponent?.arrayProductParameterizedVariables;
        if(!arrPpvs){
          arrPpvs = [];
        }
        let keyStr = idProduct;
    
        for(let i = 0; i<arrPpvs.length; i++){
          let idProductParameterizedVariable = arrPpvs[i].Id_Product_ParameterizedVariable;
          let value = arrPpvs[i].Value;
          keyStr += "_idppv_" + idProductParameterizedVariable + "_val_" + value;
        }
        return keyStr.toString();
      }
    

    
    return (<>
              {askStagedLotSelection && (
            <StagedLotsPopup
            topBar={{label:askStagedLotSelection?.productName}}
            selectableLots={askStagedLotSelection?.locationsQuantity}
             onClose={() => {
              setAskStagedLotSelection(null);
             }}
             onConfirm={(result:any) => {
                
                let selectedLot = result?.selectedLot;
                if(!selectedLot){
                  return;
                }
                let updatingLocationData:any = {};
               
                  updatingLocationData.newLotData = { //for lots we only need the lot code directly as it is calculated in the backend
                    lotValue:selectedLot.lotData.code
                  };
                
               
                  updatingLocationData.newWhData = { 
                    Id:selectedLot.Id_Warehouse,
                    code:selectedLot.warehouseData.code,
                    name:selectedLot.warehouseData.name
                  };
                
            
                  updatingLocationData.newWhLocData = { 
                    Id:selectedLot.Id_WarehouseLocation,
                    code:selectedLot.warehouseLocationData.code,
                    name:selectedLot.warehouseLocationData.name
                  };
                
           
                  if(props?.onLotSelection){
                      props.onLotSelection({
                          Id_WarehouseLocation:selectedLot.Id_WarehouseLocation,
                          warehouseLocationCode: selectedLot?.warehouseLocationData?.code,
                          warehouseLocationName: selectedLot?.warehouseLocationData?.name,
                          warehouseCode: selectedLot?.warehouseData?.code,
                          warehouseName: selectedLot?.warehouseData?.name,
                          Id_Warehouse: selectedLot?.Id_Warehouse,
                          Id_Lot: selectedLot?.Id_Lot,
                          lotCode: selectedLot?.lotData?.code
                      });
                  }
      
                  setAskStagedLotSelection(null);
             }}
            />
          )}
        <div className={styles.lotToolBtn} onClick={() => {
           let keyCurrent = buildKeyForChildProduct(props.bomChild);
        
           let stagedArrayMaterialComponentsData = props.stagedArrayMaterialComponentsData;
           let cleanStagedLocationQtyForThisProduct = [];
           if(stagedArrayMaterialComponentsData){
             for(let i = 0; i<stagedArrayMaterialComponentsData.length; i++){
               let idProduct = stagedArrayMaterialComponentsData[i].Id_Product;
               let arrayProductParameterizedVariables = stagedArrayMaterialComponentsData[i]?.arrayProductParameterizedVariables ? stagedArrayMaterialComponentsData[i].arrayProductParameterizedVariables : [];
               let key = buildKeyForChildProduct({Id_ProductChild:idProduct, arrayProductParameterizedVariables:arrayProductParameterizedVariables});

               if(keyCurrent === key){
                 let locationsQuantity = stagedArrayMaterialComponentsData[i]?.locationsQuantity ? stagedArrayMaterialComponentsData[i].locationsQuantity : [];
                 if(locationsQuantity){
                   cleanStagedLocationQtyForThisProduct.push(...locationsQuantity)
                 }
                 
               }

             }
           }

           if(cleanStagedLocationQtyForThisProduct.length === 0){
             feedbackService.notifyToast(t('no.staged.lots.available'),"error");
             return;
           }

           
           
           setAskStagedLotSelection({
             bomChild:props.bomChild,
             productName:props.bomChild?.childProduct_name,
             locationsQuantity:cleanStagedLocationQtyForThisProduct
           });
        }}>
            <div><DesignServicesIcon/></div>
            <div>{t('allocated.lots')}</div>
        </div>
    </>);
}

export default HxfStagedLotsButton;